@import "../main/abstracts/index";

.section--intro-custom-home{
    padding-top: 2.3rem;
    padding-bottom: 4rem;

    &.section--padding-top,
    &.section--padding-bottom{
        padding-top: 2.3rem;
        padding-bottom: 4rem;
    }

    @include media-breakpoint-up(sm) {
        padding-top: 3rem;

        &.section--padding-top,
        &.section--padding-bottom{
            padding-top: 3rem;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 4rem;

        &.section--padding-top,
        &.section--padding-bottom{
            padding-top: 4rem;
        }
    }

    .row > .col {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include media-breakpoint-up(lg) {
            flex-wrap: nowrap;
        }
    }

    .intro{
        width: 700px;
        max-width: 100%;
        margin-bottom: 35px;

        header{
            padding-left: 0;
        }

        .intro__icon{
            height: 55px;
            width: 55px;
            margin-bottom: 15px;

            img{
                filter: $filter__primary-2;
            }
        }

        @include media-breakpoint-up(sm) {

            header{
                padding-left: 70px;
            }

            .intro__icon{
                position: absolute;
                left: -6px;
                bottom: 9px;
                height: 62px;
                width: 62px;
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(md) {

            header{
                padding-left: 80px;
            }

            .intro__icon{
                height: 70px;
                width: 70px;
            }
        }

        @include media-breakpoint-up(lg) {
            width: 53%;
            margin-left: 0;
            margin-bottom: 0;

            header{
                padding-left: 100px;
            }

            .intro__icon{
                height: 85px;
                width: 85px;
            }
        }

        @include media-breakpoint-up(xl) {
            width: 50%;
            margin-left: 4%;
        }

        @include media-breakpoint-up(xxl) {
            width: 45%;
            margin-left: 3.5%;
        }

        @media (min-width: 1450px) {
            margin-left: 6%;

            header{
                padding-left: 115px;
            }
        }
    }

    .direct-links{
        width: 700px;
        max-width: 100%;

        @include media-breakpoint-up(lg) {
            width: 40%;
            margin-right: 0;
        }

        @include media-breakpoint-up(xl) {
            width: 37%;
            margin-right: 3.5%;
        }

        @media (min-width: 1450px) {
            width: 34%;
            margin-right: 6%;
        }

        header{
            h2{
                margin-top: 0!important;

                &:before{
                    display: none;
                }
            }
        }

        .meta-navigation{

            &__list{
                display: flex;
                flex-flow: row wrap;
                gap: 0.5rem 0;

                padding: 24px;
                margin: 0 -24px;
                justify-content: space-between;
                background-color: $color__primary-2;

                @media (min-width: 500px) {
                    margin: 0;
                    border-radius: 8px;
                    box-shadow: 0 2px 5px rgba(0,0,0,.5);
                }
            }

            &__item{
                width: 100%;
                padding: 0;
                list-style-type: none;

                @media (min-width: 375px) {
                    width: calc(50% - 10px);
                }

                &:before{
                    display: none;
                }

                &:last-child{
                    .meta-navigation__link.button{
                        border-bottom: none;
                        padding-bottom: 0;
                    }
                }

                @media (min-width: 375px) {

                    &:nth-last-child(2):nth-child(odd){
                        .meta-navigation__link.button{
                            border-bottom: none;
                            padding-bottom: 0;
                        }
                    }
                }
            }

            &__link.button{
                padding: 0 0 13px 0;
                border: none;
                border-bottom: 1px solid $color__primary-3;
                border-radius: 0!important;
                font-weight: $font-weight-light;
                color: $color__white;
                background-color: transparent;

                span{

                    &.icon{
                        width: 42px;

                        img{
                            filter: $filter__white;
                            height: 30px;
                            width: auto;
                            max-width: 40px;
                        }
                    }

                    &:last-child{
                        @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 16px, 18px);
                    }
                }
            }
        }
    }

    header{
        position: relative;
        margin-bottom: 1.8rem!important;

        h1{
            margin-bottom: 1.8rem;
        }

        .subheadline{
            display: block;
            color: $color__primary-2;
            font-weight: $font-weight-base;
            line-height: normal;
            @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 24px, 32px);
        }
    }
}
